import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import  './pages.css';
import  { Navbar } from '../components';
// import { Tidbits} from '../containers';




import { profilePhoto, profilePhoto02, profilePhoto03} from'./imports';


import Aboutbox from '../containers/about/About';
import Features from '../containers/features/Features'
import { Brand } from '../components';



const AboutMe = () => {


  return (
<>
  <ScrollToTop />
  
  
    <Navbar />
    <div className='site__pages' id="topPage">
      {/* <div className="site__header-pages section__padding" id="home">
          <div className="site__header-content" >
            <h1 className="solid__text">Who Am I?</h1>
          </div>
      </div> */}
    </div>

  
  <div className='site__aboutMe section__padding'>
      
      <div className='site__aboutMe-content'>

    
    
      
        <p>my ethos</p>
        <h3>Redefine what's possible.</h3>
        {/* <h6>#graphicdesigner #uiuxdesigner #illustrator #programmer #gamer #matcha-addict #unconventionalintrovert</h6> */}
        
        <p>Hi! I'm Taylor, a visual designer with a background in computer science.</p>

        <br />
        <p>Creating aesthetics by blending visual allure with 
          seamless user interaction is where I thrive. My focus gravitates towards ensuring that each pixel contributes not just to 
          the look, but also to the usability and delight of the final product.
        </p>

        <br />
        <p>When I'm not glued to my computer, you'll often catch me drawing, playing video games, studying Japanese, 
        or enjoying time with my friends. I also love my pomimo and blue merle pomeranian. Though, anyone who knows me knows that.
        </p>
        <br /><br />

        <div className='project-btn'>
          <a className="links-footer" href="mailto:siemenstaylor@gmail.com">
            Let's chat
              <div className='svg__container basline'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                </svg>
              </div>
          </a>
        </div>

        {/* <p><strong>Currently playing:</strong> Tears of the Kingdom</p> */}

        {/* <p><strong>Favourites:</strong> The Legend of Zelda series, Okami, Journey, Little Nightmares, The Last of Us series, Tweety and the Magic Gems</p> */}

      </div>

      
      <div className='site__aboutMe-photo site__3col'>
        <div className='site__3col-groupA'>
         <img src={profilePhoto} alt="profile"/>
        </div>
        <div className='site__3col-groupB'>
         <img src={profilePhoto02} alt="profile"/>
        </div>
        <div className='site__3col-groupC'>
         <img src={profilePhoto03} alt="profile"/>
        </div>
      </div>
      

    </div>
    <Aboutbox />

    <div className="site__container-bg">
    <Features />
    </div>

    {/* <div className='site__title section__padding'>
        <h2>Awarded in accolades.</h2>
        <p>A few badges collected throughout my odyssey.</p>
    </div> */}

    <Brand />
     

    {/* <Tidbits /> */}


</>
  )
}

export default AboutMe