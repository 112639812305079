import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import ScrollToTop from './../components/ScrollToTop';
import  './projects.css';
import  { Navbar } from './../components';

import Gallery from '../containers/gallery/Gallery';
import { proj3_imgURL1, proj3_imgURL2, proj3_imgURL4, proj3_imgURL5, proj3_imgURL6,
    proj3_imgURL8, proj3_imgURL9, proj3_imgURL10 } from'./imports';

const BOTWicons = () => {


  return (
<>
    <Navbar />
    <ScrollToTop />
    <div className='site__project' id="topPage">
    <div className="site__header section__padding">
     <div className="site__project-header-content" >
        <h1 className="solid__text">Breath of the wild Icons</h1>
        <div className='project-btn'>
            <Link to="/Breath-of-the-Wild-icons" className="site__project-link">Explore UI Art</Link>
        </div>
     </div>

    </div>

   
    <div className="site__tldr section__padding">
        <h3>TLDR;</h3>
        <div className="site__tldr-groupA">
            <p><strong>Deliverables:</strong> Game Icons</p>
            <p><strong>Project:</strong> Personal project to practice illustration and creating icons used in video games</p>
            <p><strong>My Responsibilities:</strong> Illustrating Breath of the Wild icons</p>
            <p><strong>The Challenge:</strong> Re-creating recognizable icons from a respected video game</p>
        </div>

        <div className="site__tldr-groupB">
            <p><strong>Completed:</strong> October 2022</p>
            <p><strong>Artist:</strong> Me, myself and I (Taylor)</p>
            <p><strong>Applications:</strong> Procreate</p>
            <p><strong>Published On: </strong><a className="site__project-link" href="https://www.artstation.com/artwork/2qE0ZA">Artstation </a> </p>        
            <p><strong>UI Art: </strong><Link to="/Breath-of-the-Wild-icons" className="site__project-link">Explore UI Art</Link></p>
        </div>
    </div>

    <div className='site__casestudy section__padding'>
        <div className='site__casestudy-groupA'>
            <div className="site__casestudy-image">
                <img src={proj3_imgURL1} alt="casestudy" />
            </div>
            <div className='site__casestudy-content'>
            <h3>The Purpose</h3>
                <p>Let it be known that I'm a <em>huge fan</em> of The Legend of Zelda series. 
                    So one day I decided to experiment with recreating some of the key items 
                    from Breath of the Wild to test my skills and see if I could accurately 
                    replicate some of the iconic key items that define the game’s unique style.</p>
            </div>
        </div>

        <div className='site__casestudy-groupB'>
            <div className="site__casestudy-image">
                <img src={proj3_imgURL2} alt="casestudy" />
            </div>
            <div className='site__casestudy-content'>
                <h3>The Challenge</h3>
                <p>Using my current art skills, 
                    I set out to recreate the iconic key items, aiming to capture them as closely as possible.</p>
            </div>
        </div>
    </div>

    

    <div className='site__UX-Design section__padding'>

        <div className='snapshot-img'>
             <img src={proj3_imgURL4} alt="snapshot" />
             <img src={proj3_imgURL5} alt="snapshot" />
        </div>

        <div className='site__UX-Design-content'>
                <h3>First Task</h3>
                <p>The first thing I did was choose specific items that would really showcase my artistic skills. 
                    I decided to focus on key items because they each had their own challenges and seemed to get 
                    less attention from other artists compared to the more commonly depicted food, weapons, and armor that are featured in the game.</p>
        </div>

        <div className='snapshot-img'>
             <img src={proj3_imgURL8} alt="snapshot" />
        </div>

        <div className='site__UX-Design-content'>
                <h3>Process Breakdown & Inspiration</h3>
                <p> With the goal of recreating key items in mind, 
                    I reviewed each one and considered two things: whether it would be a 
                    challenge to illustrate, and if it would be recognizable enough to 
                    create a "Hey! That's from Breath of the Wild" moment when seen on its own.</p>


                <p>Another reason I was motivated to recreate some of these key items was because of their resemblance to the spells gathered 
                    in Orcarina of Time: Din's Fire vs Daruk's Protection, Farore's Wind vs Revali's Gale, and Nayru's Love vs Mipha's Grace.
                </p>

        </div>

        <div className='site__2col'>
            <div className='site__2col-groupA'>
                <img src={proj3_imgURL9} alt="snapshot" />
            </div>
            <div className='site__2col-groupB'>
            <img src={proj3_imgURL10} alt="snapshot" />
            </div>
        </div>

    </div>

    <div className='site__UI-Design section__padding'>

        <div className='snapshot-img'>
             <img src={proj3_imgURL6} alt="snapshot" />
        </div>

        <div className='site__UI-Design-content'>
                <h3>Outcomes</h3>
                <p>Overall, I'm really proud of how each icon came together—I think 
                    they capture the essence of their in-game counterparts well.</p>

                <p>Moving forward, I plan to build on this experience by creating/recreating more Zelda fan art 
                    and challenging myself to design original icons with the same level of complexity and style. </p>
        </div>

        <div className='site__back-to-top'>
            <Link to="#topPage" className="links-nav">Back to top</Link>
        </div>

    </div>

    

    </div>

    <div className='site__blog-heading'>
    <h1>Discover more.</h1>
    </div>
  
    <Gallery />
</>
  )
}

export default BOTWicons