import React from 'react';
import { Photo } from '../../components';
import { photo01, photo02, photo03, photo04, photo05, photo06} from'./imports';
import './gallery.css';

const Gallery = () => {
  return (
    <>
    <div className="site__gallery section__padding" id="gallery">
    
      <div className="site__gallery-container">
       
        <div className="site__gallery-container_groupB">
          <Photo num="01" imgUrl={photo02} title="Breath of the Wild Icons" type="Iconography"  URL="/Breath-of-the-Wild-icons"/>
          <Photo num="02" imgUrl={photo01} title="Infinitea" type="Product Design" URL="/Infinitea"/>
          <Photo num="03" imgUrl={photo04} title="Minus The" type="Logo Design" URL="/Minus-The"/>
          <Photo num="04" imgUrl={photo06} title="Avatars & Emojis" type="Iconography" URL="/Avatars-Emojis"/>
          <Photo num="05" imgUrl={photo05} title="RevoltWear" type="Visual Identity"  URL="/RevoltWear"/>

        </div>
      </div>
    </div>
        </>
  )
}

export default Gallery