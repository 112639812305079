import React from 'react';
import { Photo } from '../../components';

// import { Article } from '../../components';
import { blog01, blog02, blog03, blog04, blog05} from'./imports';
import './blog.css';

const Blog = () => {
  return (
    <div className="site__blog section__padding" id="ux-ui">
      <div className="site__blog-container">
        <div className="site__blog-container_groupB">
          <Photo num="01" imgUrl={blog05} title="Coming Soon" type=""  URL=""/>
          <Photo num="02" imgUrl={blog01} title="ArtiTicket" type="UX Design" URL="/Artiticket"/>
          {/* <Photo num="03" imgUrl={blog02} title="Breath of the Wild Icons" type="Iconography"  URL="/Breath-of-the-Wild-icons"/> */}
          <Photo num="04" imgUrl={blog04} title="Dark Star" type="UI Design" URL="/Dark-Star" />
          
          {/* <Photo num="05" imgUrl={blog03} title="Bernie's Supper Club & Cinema" type="Web Design" URL="/Bernies-Supper-Club-&-Cinema"/> */}
        </div>
      </div>


    </div>
  )
}

export default Blog