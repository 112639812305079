import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import ScrollToTop from '../components/ScrollToTop';
import  './projects.css';
import  { Navbar } from '../components';

import Gallery from '../containers/gallery/Gallery';
import { proj2_imgURL1, proj2_imgURL2, proj2_imgURL3, proj2_imgURL4,
    proj2_imgURL5, proj2_imgURL6, proj2_imgURL7} from'./imports';
import {proj2_HimgURL1, proj2_HimgURL2, proj2_HimgURL3, proj2_HimgURL4, proj2_HimgURL5} from './hImgs-imports';

const SRDIllustrations_UI = () => {


  return (
<>
    <Navbar />
    <ScrollToTop />
    <div className='site__project' id="topPage">
        <div className="site__project-header-imgCollage section__padding">
            <div className='site__project-header-imgCollage-groupA'>
                <img src={proj2_HimgURL1} alt="Four avatars" />
            </div>
            <div className='site__project-header-imgCollage-groupB'>
                 <img src={proj2_HimgURL2} alt="Close up of the social post used featuring team avatars" />
            </div>
            <div className='site__project-header-imgCollage-groupC'>
                <img src={proj2_HimgURL3} alt="Close up of one male avatar" />
                <img src={proj2_HimgURL4} alt="Close up of one female avatar" />
            </div>
            <div className='site__project-header-imgCollage-groupD'>
                <img src={proj2_HimgURL5} alt="Seven emojis vertically stacked on eachother" />
            </div>
        </div>

   
        <div className="site__tldr section__padding">
            <div className="site__tldr-groupA" id="projectTitle-UI">
                <h1 className="solid__text-UI">Avatars & Emojis</h1>
                {/* <div className='project-btn'>
                    <Link to="/Breath-of-the-Wild-icons-case-study" className="links-nav">View Case Study</Link>
                </div> */}
            </div>
            <div className="site__tldr-groupA" id="project-UI">
                <p><strong>Deliverables: </strong>Iconography</p>
                <p><strong>Project: </strong>Custom team avatars and emojis</p>
                <p><strong>My Responsibilities: </strong>Illustrating avatars for each team member and emojis for journey maps</p>
                <p><strong>The Challenge: </strong>Creating original illustrations from scratch and ensuring each avatar is an accurate representation of the individual team members</p>
            </div>

            <div className="site__tldr-groupC">
                <p><strong>Completed: </strong>2024</p>
                <p><strong>Applications: </strong>Adobe Illustrator</p>
            </div>
        </div>

        
        <div className='site__UX-Design section__padding'>

            <div className='snapshot-img'>
                  <img src={proj2_imgURL1} alt="Custom illustrations project thumbnail featuring an avatar close-up and a few emojis" />
                  <img src={proj2_imgURL2} alt="A display of 12 custom emojis for internal materials such as journey maps" />
            </div>


            <div className='video__container'>
                <video class="video" loop autoPlay muted playsInline>
                    <source src={proj2_imgURL6} type="video/mp4 "alt="rotating avatars animation" />
                    Your browser does not support the video tag.
                </video>
            </div>

            <div className='snapshot-img'>
                  <img src={proj2_imgURL3} alt="Display of all 14 avatar designs. One for each team memeber" />
            </div>
            
            <div className='site__2col'>
                <div className='site__2col-groupA'>
                <img src={proj2_imgURL4} alt="Emojis displayed on a grid" />
                </div>
                <div className='site__2col-groupB'>
                  <img src={proj2_imgURL5} alt="A line-up of different avatar designs explored during the design process" />
                </div>
            </div>

            <div className='snapshot-img'>
                  <img src={proj2_imgURL7} alt="A Season's Greetings holiday social post using all avatars (at the time)" />
            </div>

            

            <div className='site__back-to-top'>
                <Link to="#topPage" className="links-nav">Back to top</Link>
            </div>

        </div>

    </div>

    <div className='site__blog-heading'>
    <h1>Discover more.</h1>
    </div>
  
    <Gallery />
</>
  )
}

export default SRDIllustrations_UI