import React from 'react';
import { Feature } from '../../components';
import './about.css';

import ps from '../../assets/logos/PS-logo.png';
import ind from '../../assets/logos/Indesign-logo.png';
import ai from '../../assets/logos/Illustrator-logo.png';
import fig from '../../assets/logos/Figma-logo.png';
import not from '../../assets/logos/Notion-logo.png';
import pro from '../../assets/logos/procreate-logo.png';
import un from '../../assets/logos/unreal-logo.png';
import final from '../../assets/logos/FinalCut-pro.png';
import mi from '../../assets/logos/miro-logo.png';



const About = () => {
  return (
    <div className="site__about section__margin" id="about">
      {/* <div className="site__about-feature">
        <Feature title="About" text=" 
         Hi, my name is Taylor. I am a UI/UX designer and illustrator with a rare fusion of skills including graphic design and a background in computer science. 
        
         Besides immersing myself in design and researching the latest trends, I enjoy thrifting, video games, and a nice cup of #000 tea. I also love my Pomimo and blue merle Pomeranian. Though, anyone who knows me knows that." />
      </div> */}

      <div className="site__about-heading">
        <h3>Quick insights.</h3>
      </div>

      <div className="site__about-container">
        <Feature title="Some very normal numbers" text="10M+ views on Giphy" />
        <Feature title="Bridge the gap" text="Computer Science & Visual Design background" />
        <Feature title="Programs" img={ps} img2={ai} img3={ind} img4={fig} img5={pro} img6={final} img7={mi}/>
      </div>

      

    </div>
  )
}

export default About;